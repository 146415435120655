import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  clearAplyFilters,
  setAplyFilter,
  setApplyFilterQuery,
  setFullAplyFilter,
  setLastActiveOrder,
  setVideoFilter,
} from "../store/applyFilterSlice";
import { variables } from "../Variables";
import "./Applies.css";
import Pagination from "./pagination/Pagination";

var ENTER_KEY = 13;

export function Applies(props) {
  var navigate = useNavigate();
  var dispatch = useDispatch();

  var { 0: applies, 1: setApplies } = useState([]);
  var { 0: totalCount, 1: setTotalCount } = useState("");
  var { 0: filterQuery, 1: setFilterQuery } = useState("");
  var { 0: modalTitle, 1: setModalTitle } = useState("");
  var { 0: phone, 1: setPhone } = useState("7");
  var { 0: name, 1: setName } = useState("");
  var { 0: vacancy_id, 1: setVacancy_id } = useState("");
  var { 0: position, 1: setPosition } = useState("");
  var { 0: vacancies, 1: setVacancies } = useState([]);

  var apply_filter = useSelector((state) => state.apply_filter);
  var { 0: searchParams, 1: setSearchParams } = useSearchParams();
  var { 0: currentPage, 1: setCurrentPage } = useState("");

  var isFirst = useRef();

  useEffect(() => {
    isFirst.current = false;
  }, []);

  useLayoutEffect(() => {
    if (props.customerId) {
      navigate("/vacancy");
    }
  }, []);

  var refreshList = (page, newState = null) => {
    let new_apply_filter;
    if (newState) {
      new_apply_filter = newState;
    } else {
      new_apply_filter = apply_filter;
    }
    let filter = "";
    if (new_apply_filter.statusFilter.toString() !== "1") {
      filter += "&&status=" + new_apply_filter.statusFilter;
    }
    if (new_apply_filter.filter !== "") {
      filter += "&&filter=" + new_apply_filter.filter;
    }
    if (new_apply_filter.vacancyFilter.toString() !== "-1") {
      filter += "&&vacancy=" + new_apply_filter.vacancyFilter;
    }
    if (new_apply_filter.videoFilter) {
      filter += "&&has_video=" + new_apply_filter.videoFilter;
    }
    if (new_apply_filter.lastActiveOrder) {
      filter += "&&lastActiveOrder=" + new_apply_filter.lastActiveOrder;
    }
    fetch(variables.API_URL + "/api/apply/?page=" + page + filter, {
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApplies(data.data);
        setTotalCount(data.count);
      });
  };

  useLayoutEffect(() => {
    dispatch(clearAplyFilters());
  }, []);

  useLayoutEffect(() => {
    if (!isFirst.current) {
      isFirst.current = true;
    } else {
      refreshList(1);
      setCurrentPage(1);
      searchParams.set("page", 1);
      setSearchParams(searchParams, { replace: true });
    }
  }, [apply_filter]);

  useLayoutEffect(() => {
    let state;
    state = dispatch(
      setFullAplyFilter({
        statusFilter: searchParams.get("statusFilter") || 1,
        vacancyFilter: searchParams.get("vacancyFilter") || -1,
        filter: searchParams.get("filter") || "",
        videoFilter: searchParams.get("videoFilter") === "true" ? true : "",
        lastActiveOrder:
          searchParams.get("lastActiveOrder") === "true" ? true : "",
      })
    );
    setFilterQuery(searchParams.get("filter") || "");
    refreshList(Number(searchParams.get("page")) || 1, state.payload);
    setCurrentPage(Number(searchParams.get("page")) || 1);
  }, []);

  var changePage = (page) => {
    window.scrollTo(0, 0);
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    setCurrentPage(page);
    refreshList(page);
  };

  useLayoutEffect(() => {
    get_vacancies();
  }, []);

  var get_vacancies = () => {
    fetch(variables.API_URL + "/api/vacancy_id_and_name/", {
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVacancies(data);
      });
  };

  var handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      console.log("handleKeyDown e", e);
      searchParams.set("filter", e.target.value);
      setSearchParams(searchParams, { replace: true });

      dispatch(
        setApplyFilterQuery({
          filter: e.target.value,
        })
      );
    }
  };

  var changeFilterQuery = (e) => {
    setFilterQuery(e.target.value);
  };

  var changeSelectStatusFilter = (e) => {
    searchParams.set(
      "statusFilter",
      e.target.value === "1" ? "" : e.target.value
    );
    setSearchParams(searchParams, { replace: true });

    dispatch(
      setAplyFilter({
        statusFilter: e.target.value,
      })
    );
  };

  var changeSelectVacancyFilter = (e) => {
    searchParams.set(
      "vacancyFilter",
      e.target.value === "-1" ? "" : e.target.value
    );
    setSearchParams(searchParams, { replace: true });

    dispatch(
      setAplyFilter({
        vacancyFilter: e.target.value,
      })
    );
  };

  var changeHasVideoFilter = () => {
    searchParams.set(
      "videoFilter",
      !apply_filter.videoFilter ? "true" : "false"
    );
    setSearchParams(searchParams, { replace: true });

    dispatch(
      setVideoFilter({
        videoFilter: !apply_filter.videoFilter,
      })
    );
  };

  var changeLastActiveOrder = () => {
    searchParams.set(
      "lastActiveOrder",
      !apply_filter.lastActiveOrder ? "true" : "false"
    );
    setSearchParams(searchParams, { replace: true });

    dispatch(
      setLastActiveOrder({
        lastActiveOrder: !apply_filter.lastActiveOrder,
      })
    );
  };

  var deleteApply = (apply_id, e) => {
    e.stopPropagation();
    var result = window.confirm("Вы уверены что хотите удалить отклик?");
    if (result) {
      fetch(variables.API_URL + "/api/delete_apply/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        body: JSON.stringify({
          apply_id: apply_id,
        }),
      })
        .then((res) => {
          refreshList();
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  var checkPhone = (number) => {
    setPhone(number);
  };

  var addClick = () => {
    setModalTitle("Добавить отклик");
    setPhone("7");
    setName("");
    setVacancy_id("");
    setPosition("");
  };

  var addApply = () => {
    fetch(variables.API_URL + "/api/create_from_web/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        phone: phone,
        name: name,
        vacancy_id: vacancy_id,
        position: position,
        username: props.username,
      }),
    })
      .then((data) => {
        refreshList();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  var clickRowHandler = (e, apply_id) => {
    if (e.ctrlKey) window.open("/apply/" + apply_id, "_blank");
    else navigate("/apply/" + apply_id);
  };

  var onAuxClickHandler = (e, apply_id) => {
    if (e.button == 1) {
      window.open("/apply/" + apply_id, "_blank");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px 0",
        }}
      >
        <button
          style={{
            maxHeight: "38px",
            minHeight: "38px",
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            marginTop: "6px",
          }}
          type="button"
          className="btn btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={addClick}
        >
          <span
            style={{ fontSize: "30px", fontWeight: "500", lineHeight: "20px" }}
          >
            +
          </span>
          <div>Добавить отклик</div>
        </button>
      </div>
      <div className="filter_area">
        <div className="toggle_area">
          <div className="applies_switcher">
            <input
              className="hide applies_switcher__input"
              type="checkbox"
              value=""
              onChange={changeHasVideoFilter}
              id="flexCheckChecked"
              checked={apply_filter.videoFilter}
            />
            <label
              htmlFor={"flexCheckChecked"}
              className="applies_switcher__switcher"
            ></label>
            <label className="toggle_name" htmlFor="flexCheckChecked">
              С видео
            </label>
          </div>
          <div className="applies_switcher">
            <input
              id={"isLastActiveOrder"}
              className="hide applies_switcher__input"
              type="checkbox"
              checked={apply_filter.lastActiveOrder}
              onChange={changeLastActiveOrder}
            />
            <label
              htmlFor={"isLastActiveOrder"}
              className="applies_switcher__switcher"
            ></label>
            <label className="toggle_name" htmlFor="isLastActiveOrder">
              Последняя активность
            </label>
          </div>
        </div>
        <select
          className="form-select"
          onChange={changeSelectStatusFilter}
          value={apply_filter.statusFilter}
        >
          <option key={1} value={1}>
            Статус
          </option>
          <option key={2} value={"В работе"}>
            В работе
          </option>
          <option key={3} value={"Отказ"}>
            Отказ
          </option>
        </select>
        <select
          className="form-select"
          onChange={changeSelectVacancyFilter}
          value={apply_filter.vacancyFilter}
        >
          <option key={-1} value={-1}>
            Вакансии
          </option>
          {vacancies?.map((vacancy) => {
            return (
              <option key={vacancy.id} value={vacancy.id}>
                {vacancy.id} {vacancy.name}
              </option>
            );
          })}
        </select>
        <input
          type="text"
          className="form-control"
          placeholder="Введите id вакансии/person или имя..."
          value={filterQuery}
          onChange={changeFilterQuery}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div style={{ textAlign: "end" }}>Всего откликов: {totalCount}</div>
      <div className="table_wrap">
        <table className="table">
          <thead>
            <tr>
              <th width="5%">Apply ID</th>
              <th width="5%">Фото</th>
              <th width="6%">Person ID</th>
              <th width="15%">Компания</th>
              <th width="15%">ФИО</th>
              <th width="5%">Возраст</th>
              <th width="10%">Статус</th>
              <th>Должность</th>
              <th width="7%">ID вакансии</th>
              <th width="4%"></th>
            </tr>
          </thead>
          <tbody>
            {applies?.map((apply) => (
              <tr
                key={apply.id}
                className="apply_row"
                onClick={(e) => {
                  clickRowHandler(e, apply.id);
                }}
                onAuxClick={(e) => {
                  onAuxClickHandler(e, apply.id);
                }}
              >
                <td>
                  <p title={apply.id}>{apply.id}</p>
                </td>
                <td
                  style={{
                    position: "relative",
                    fontSize: "0px",
                    minWidth: "80px",
                  }}
                >
                  <img
                    style={{ objectFit: "cover" }}
                    src={variables.API_URL + "/" + apply.photo + "?size=60x60"}
                    alt="Profile"
                  />
                </td>
                <td>
                  <p title={apply.person_id}>{apply.person_id}</p>
                </td>
                <td>
                  <div
                    title={
                      apply.vacancy_id == 0
                        ? apply.extension_company_name
                        : apply.company
                    }
                  >
                    {apply.vacancy_id == 0
                      ? apply.extension_company_name
                      : apply.company}
                  </div>
                </td>
                <td>
                  <div title={apply.name}>{apply.name}</div>
                </td>
                <td>
                  <p title={apply.age}>{apply.age}</p>
                </td>
                <td>
                  <p title={apply.status}>{apply.status}</p>
                </td>
                <td>
                  <div title={apply.position}>{apply.position}</div>
                </td>
                <td>
                  <p title={apply.vacancy_id}>{apply.vacancy_id}</p>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => deleteApply(apply.id, e)}
                    title={"Удалить отклик"}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!!currentPage && !!totalCount && (
          <div className="text-center">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={100}
              onPageChange={(page) => changePage(page)}
            />
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centred"
          style={{ maxWidth: "333px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{modalTitle}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                className="input-group mb-3"
                style={{ ".reactTelInput .formControl": "" }}
              >
                <PhoneInput
                  country={"ru"}
                  value={phone}
                  onChange={(phone_n) => checkPhone(phone_n)}
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  placeholder="ФИО"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={vacancy_id}
                  placeholder="ID вакансии"
                  onChange={(e) => setVacancy_id(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={position}
                  placeholder="Должность"
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary float-start"
              data-bs-dismiss="modal"
              onClick={() => addApply()}
            >
              Создать
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
